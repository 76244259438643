import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './GoalsTracker.css';

// Utiliser l'URL de l'API à partir des variables d'environnement
const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

// Fonction pour calculer les dépenses en cours
const calculateCurrentExpenses = (bets) => {
    return bets.reduce((acc, bet) => {
        return bet.result ? acc : acc + parseFloat(bet.stake);
    }, 0).toFixed(2);
};

const GoalsTracker = () => {
    const [goals, setGoals] = useState([]);
    const [newGoal, setNewGoal] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [editGoalValue, setEditGoalValue] = useState('');
    const [totalCumulativeProfit, setTotalCumulativeProfit] = useState(0);
    const [currentExpenses, setCurrentExpenses] = useState(0); // État pour les dépenses en cours
    const [lostExpenses, setLostExpenses] = useState(0); // État pour les dépenses perdues

    useEffect(() => {
        const fetchGoals = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/goals`);
                console.log('Goals response:', response.data); // Ajouter un log
                if (Array.isArray(response.data)) {
                    setGoals(response.data);
                } else {
                    console.error('Expected an array but got:', response.data);
                }
            } catch (error) {
                console.error('Error fetching goals:', error);
            }
        };

        const fetchClosedSeries = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/series/closed`);
                console.log('Closed series response:', response.data); // Ajouter un log
                if (Array.isArray(response.data)) {
                    let calculatedTotalCumulativeProfit = 0;
                    response.data.forEach(series => {
                        let cumulativeProfit = 0;
                        if (Array.isArray(series.bets)) {
                            series.bets.forEach(bet => {
                                const profitOrLoss = bet.result === 'won' ? (parseFloat(bet.stake) * parseFloat(bet.odds) - parseFloat(bet.stake)) : -parseFloat(bet.stake);
                                cumulativeProfit += profitOrLoss;
                            });
                        }
                        calculatedTotalCumulativeProfit += cumulativeProfit;
                    });
                    setTotalCumulativeProfit(calculatedTotalCumulativeProfit);
                } else {
                    console.error('Expected an array but got:', response.data);
                }
            } catch (error) {
                console.error('Error fetching closed series:', error);
            }
        };

        const fetchOpenSeries = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/series/open`);
                console.log('Open series response:', response.data); // Ajouter un log
                if (Array.isArray(response.data)) {
                    let calculatedCurrentExpenses = 0;
                    response.data.forEach(series => {
                        if (Array.isArray(series.bets)) {
                            const seriesCurrentExpenses = calculateCurrentExpenses(series.bets);
                            calculatedCurrentExpenses += parseFloat(seriesCurrentExpenses);
                        }
                    });
                    setCurrentExpenses(calculatedCurrentExpenses);
                } else {
                    console.error('Expected an array but got:', response.data);
                }
            } catch (error) {
                console.error('Error fetching current expenses:', error);
            }
        };

        const fetchLostExpenses = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/series/open/lost-expenses`);
                console.log('Open series with lost expenses response:', response.data); // Ajouter un log
                if (Array.isArray(response.data)) {
                    let calculatedLostExpenses = 0;
                    response.data.forEach(series => {
                        calculatedLostExpenses += parseFloat(series.lostExpenses);
                    });
                    setLostExpenses(calculatedLostExpenses);
                } else {
                    console.error('Expected an array but got:', response.data);
                }
            } catch (error) {
                console.error('Error fetching lost expenses:', error);
            }
        };

        fetchGoals();
        fetchClosedSeries();
        fetchOpenSeries(); // Appel pour récupérer les dépenses en cours
        fetchLostExpenses(); // Appel pour récupérer les dépenses perdues
    }, []);

    const addGoal = async () => {
        if (newGoal && !isNaN(newGoal)) {
            try {
                const response = await axios.post(`${API_URL}/api/goals`, { value: parseFloat(newGoal) });
                setGoals([...goals, response.data]);
                setNewGoal('');
            } catch (error) {
                console.error('Error adding goal:', error);
            }
        }
    };

    const deleteGoal = async (id) => {
        try {
            await axios.delete(`${API_URL}/api/goals/${id}`);
            setGoals(goals.filter(goal => goal.id !== id));
        } catch (error) {
            console.error('Error deleting goal:', error);
        }
    };

    const startEditingGoal = (index) => {
        setEditingIndex(index);
        setEditGoalValue(goals[index].value);
    };

    const saveEditedGoal = async () => {
        if (editGoalValue && !isNaN(editGoalValue)) {
            try {
                const goal = goals[editingIndex];
                const response = await axios.put(`${API_URL}/api/goals/${goal.id}`, { value: parseFloat(editGoalValue) });
                const updatedGoals = goals.map((g, index) => (index === editingIndex ? response.data : g));
                setGoals(updatedGoals);
                setEditingIndex(null);
                setEditGoalValue('');
            } catch (error) {
                console.error('Error updating goal:', error);
            }
        }
    };

    return (
        <div className="goals-tracker-container">
            <h1>Profit Goals Tracker</h1>
            <div className="goals-list">
                {goals.map((goal, index) => (
                    <div key={goal.id} className={`goal ${totalCumulativeProfit >= goal.value ? 'achieved' : ''}`}>
                        {editingIndex === index ? (
                            <>
                                <input
                                    type="text"
                                    value={editGoalValue}
                                    onChange={(e) => setEditGoalValue(e.target.value)}
                                />
                                <button onClick={saveEditedGoal}>Save</button>
                            </>
                        ) : (
                            <>
                                <span>Goal: {goal.value} €</span>
                                {totalCumulativeProfit >= goal.value && <span className="achieved-text">Achieved!</span>}
                                <button onClick={() => startEditingGoal(index)}>Edit</button>
                                <button onClick={() => deleteGoal(goal.id)}>Delete</button>
                            </>
                        )}
                    </div>
                ))}
            </div>
            <div className="add-goal">
                <input
                    type="text"
                    value={newGoal}
                    onChange={(e) => setNewGoal(e.target.value)}
                    placeholder="New Goal (€)"
                />
                <button onClick={addGoal}>Add Goal</button>
            </div>
            <div className="total-profit">
                <h2>Total Cumulative Profit: {totalCumulativeProfit.toFixed(2)} €</h2>
                <h3>Current Expenses: {currentExpenses} €</h3> {/* Affichage des dépenses en cours */}
                <h3>Lost Expenses: {lostExpenses} €</h3> {/* Affichage des dépenses perdues */}
            </div>
        </div>
    );
};

export default GoalsTracker;
