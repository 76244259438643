import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa'; // Importer l'icône FaEdit
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './SeriesDetails.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

function SeriesDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState(null);
    const [teams, setTeams] = useState([]);
    const [championships, setChampionships] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [totalLosses, setTotalLosses] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [newSeriesName, setNewSeriesName] = useState('');
    const [newSport, setNewSport] = useState(''); // Ajouter un état pour le sport
    const [isEditingSport, setIsEditingSport] = useState(false); // Ajouter un état pour l'édition du sport
    const [currentExpenses, setCurrentExpenses] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [splitCount, setSplitCount] = useState(1);

    const [formData, setFormData] = useState({
        team_name: '',
        odds: '',
        stake: '',
        profit: '',
        date: new Date().toISOString().split('T')[0],
        bookmaker: ''
    });

    const [editTeamName, setEditTeamName] = useState('');
    const [editBetId, setEditBetId] = useState(null);

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/series/${id}`);
                console.log('Series details fetched:', response.data); // Log des détails de la série
                setDetails(response.data);
                setNewSeriesName(response.data.name);
                setNewSport(response.data.sport); // Initialiser le sport
                calculateTotalLosses(response.data.bets);
                calculateCurrentExpenses(response.data.bets);
                calculateTotalExpenses(response.data.bets);
            } catch (error) {
                console.error('Error fetching series details:', error);
            }
        };

        const fetchTeams = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/teams`);
                console.log('Teams fetched:', response.data); // Log des équipes
                setTeams(response.data);
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        };

        const fetchChampionships = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/teams/championships`);
                console.log('Championships fetched:', response.data); // Log des championnats
                setChampionships(response.data);
            } catch (error) {
                console.error('Error fetching championships:', error);
            }
        };

        fetchDetails();
        fetchTeams();
        fetchChampionships();
    }, [id, refresh]);

    const handleRefresh = () => {
        setRefresh(!refresh);
    };

    const calculateTotalLosses = (bets) => {
        const losses = bets.reduce((acc, bet) => {
            return bet.result === 'lost' ? acc + parseFloat(bet.stake) : acc;
        }, 0);
        setTotalLosses(losses);
    };

    const calculateCurrentExpenses = (bets) => {
        const expenses = bets.reduce((acc, bet) => {
            return bet.result ? acc : acc + parseFloat(bet.stake);
        }, 0);
        setCurrentExpenses(expenses);
    };

    const calculateTotalExpenses = (bets) => {
        const expenses = bets.reduce((acc, bet) => acc + parseFloat(bet.stake), 0);
        setTotalExpenses(expenses);
    };

    const markBetAs = async (betId, result) => {
        try {
            await axios.put(`${API_URL}/api/series/bets/${betId}/status`, { result });
            handleRefresh();
        } catch (error) {
            console.error(`Failed to mark bet as ${result}:`, error);
            alert(`Failed to mark bet as ${result}.`);
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSeriesNameChange = (e) => {
        setNewSeriesName(e.target.value);
    };

    const handleSeriesNameSave = async () => {
        try {
            console.log('Saving new series name:', newSeriesName);  // Log the series name being sent
    
            const response = await axios.put(`${API_URL}/api/series/${id}/name`, { name: newSeriesName });
            console.log('Update response:', response.data);  // Log the response from the server
    
            setDetails({ ...details, name: newSeriesName });
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating series name:', error);  // Log the error details
            alert('Failed to update series name.');
        }
    };

    const handleSportChange = (e) => {
        setNewSport(e.target.value);
    };

    const handleSportSave = async () => {
        try {
            const response = await axios.put(`${API_URL}/api/series/${id}/sport`, { sport: newSport });
            setDetails({ ...details, sport: newSport });
            setIsEditingSport(false);
        } catch (error) {
            console.error('Error updating sport:', error);
            alert('Failed to update sport.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        console.log('Form data updated:', { ...formData, [name]: value }); // Log des données du formulaire
    };

    const handleOddsChange = (e) => {
        const odds = e.target.value;
        const profit = formData.profit;
        const calculatedStake = (parseFloat(profit) + totalLosses) / (parseFloat(odds) - 1);
        setFormData({ ...formData, odds, stake: calculatedStake.toFixed(2) });
        console.log('Odds changed:', { ...formData, odds, stake: calculatedStake.toFixed(2) }); // Log des données du formulaire
    };

    const handleProfitChange = (e) => {
        const profit = e.target.value;
        const odds = formData.odds;
        const calculatedStake = (parseFloat(profit) + totalLosses) / (parseFloat(odds) - 1);
        setFormData({ ...formData, profit, stake: calculatedStake.toFixed(2) });
        console.log('Profit changed:', { ...formData, profit, stake: calculatedStake.toFixed(2) }); // Log des données du formulaire
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log('Submitting bet:', formData);  // Log the bet data being sent
            await axios.post(`${API_URL}/api/series/${details.series_id}/bets`, formData);
            setFormData({
                team_name: '',
                odds: '',
                stake: '',
                profit: '',
                date: new Date().toISOString().split('T')[0],
                bookmaker: ''
            });
            handleRefresh();
        } catch (error) {
            console.error('Failed to submit bet:', error);
            alert('Failed to submit the bet.');
        }
    };

    const handleDeleteBet = async (betId) => {
        try {
            await axios.delete(`${API_URL}/api/series/bets/${betId}`);
            handleRefresh();
        } catch (error) {
            console.error('Failed to delete bet:', error);
            alert('Failed to delete bet.');
        }
    };

    const handleSplitCountChange = (e) => {
        setSplitCount(e.target.value);
    };

    const handleSplitSeries = async () => {
        const countInt = parseInt(splitCount, 10);
        if (countInt <= 0 || countInt > 10) {
            alert('Please enter a valid number of series to split into (1-10).');
            return;
        }

        try {
            await axios.post(`${API_URL}/api/series/${id}/split`, { count: countInt });
            handleRefresh();
            alert(`Series split into ${countInt} new series successfully.`);
        } catch (error) {
            console.error('Error splitting series:', error);
            alert('Error splitting series.');
        }
    };

    const handleEditTeamName = (betId, currentTeamName) => {
        setEditBetId(betId);
        setEditTeamName(currentTeamName);
    };

    const handleTeamNameChange = (event) => {
        setEditTeamName(event.target.value);
    };

    const handleTeamNameSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.put(`${API_URL}/api/bets/update-team-name/${editBetId}`, {
                team_name: editTeamName
            });
            setRefresh(!refresh);
            setEditBetId(null);
            setEditTeamName('');
        } catch (error) {
            console.error('Error updating team name:', error);
        }
    };

    const getTeamNameById = (teamId, fallbackName) => {
        const team = teams.find(t => t.id === teamId);
        return team ? team.name : fallbackName;
    };

    if (!details) {
        return <p>Loading details...</p>;
    }

    return (
        <div className="series-details-container">
            <div className="series-summary">
                <div className="series-name">
                    <h2>📊 {isEditing ? (
                        <input
                            type="text"
                            value={newSeriesName}
                            onChange={handleSeriesNameChange}
                        />
                    ) : (
                        details.name || 'undefined'
                    )}</h2>
                    {isEditing ? (
                        <button onClick={handleSeriesNameSave}>Save</button>
                    ) : (
                        <button onClick={handleEditToggle}>Edit</button>
                    )}
                </div>
                <div className="series-info">
                    <p>📅 Créée le: {new Date(details.created_at).toLocaleDateString()}</p>
                    <p>{details.is_closed ? '🛑 Fermée' : '⏳ Ouverte'}</p>
                    <p>💰 En-cours: {currentExpenses.toFixed(2)} €</p>
                    <p>💸 Dépenses totales: {totalExpenses.toFixed(2)} €</p>
                    <div className="series-sport">
                        <p>🏅 Sport: {isEditingSport ? (
                            <select value={newSport} onChange={handleSportChange}>
                                <option value="foot">Foot</option>
                                <option value="tennis">Tennis</option>
                                <option value="basket">Basket</option> {/* Ajout de l'option Basket */}
                            </select>
                        ) : (
                            details.sport || 'undefined'
                        )}</p>
                        {isEditingSport ? (
                            <button onClick={handleSportSave}>Save</button>
                        ) : (
                            <button onClick={() => setIsEditingSport(true)}>Edit</button>
                        )}
                    </div>
                </div>
                <div className="series-split-series" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                    <h2>⚡ Split Series</h2>
                    <input
                        type="number"
                        value={splitCount}
                        onChange={handleSplitCountChange}
                        placeholder="Nombre de nouvelles séries"
                        min="1"
                        max="10"
                    />
                    <button onClick={handleSplitSeries}>Split</button>
                </div>
            </div>

            <div className="series-bets-list">
                <h2>📋 Historique des paris</h2>
                <table>
                    <thead>
                        <tr>
                            <th className="bet-id-col">ID</th>
                            <th className="team-col">Équipe</th>
                            <th className="bookmaker-col">Book</th>
                            <th className="cote-col">Cote</th>
                            <th className="profit-col">Profit</th>
                            <th className="stake-col">Mise</th>
                            <th className="date-col">Date</th>
                            <th className="actions-col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.bets.map((bet, index) => (
                            <tr key={index}>
                                <td className="bet-id-col">{bet.bet_id}</td>
                                <td className="team-col">
                                    {editBetId === bet.bet_id ? (
                                        <form onSubmit={handleTeamNameSubmit}>
                                            <input
                                                type="text"
                                                value={editTeamName}
                                                onChange={handleTeamNameChange}
                                            />
                                            <button type="submit">Enregistrer</button>
                                        </form>
                                    ) : (
                                        <>
                                            {getTeamNameById(bet.team_id, bet.team_name)}
                                            <FaEdit className="edit-icon" onClick={() => handleEditTeamName(bet.bet_id, getTeamNameById(bet.team_id, bet.team_name))} />
                                        </>
                                    )}
                                </td>
                                <td className="bookmaker-col">{bet.bookmaker}</td>
                                <td className="cote-col">{bet.odds}</td>
                                <td className="profit-col">{parseFloat(bet.profit).toFixed(2)} €</td>
                                <td className="stake-col">{parseFloat(bet.stake).toFixed(2)} €</td>
                                <td className="date-col">{new Date(bet.date).toLocaleDateString()}</td>
                                <td className="actions-col">
                                    <div className="action-buttons">
                                        {bet.result ? (
                                            <>
                                                <span>{bet.result === 'won' ? 'Gagné' : 'Perdu'}</span>
                                                <button onClick={() => markBetAs(bet.bet_id, bet.result === 'won' ? 'lost' : 'won')}>
                                                    {bet.result === 'won' ? '❌' : '✔️'}
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button onClick={() => markBetAs(bet.bet_id, 'won')}>✔️</button>
                                                <button onClick={() => markBetAs(bet.bet_id, 'lost')}>❌</button>
                                            </>
                                        )}
                                        <button onClick={() => handleDeleteBet(bet.bet_id)}>🗑️</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="bet-id-col"></td>
                            <td className="team-col">
                                <Autocomplete
                                    options={teams}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <li {...props} className="autocomplete-option">
                                            {option.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sélectionner une équipe"
                                            variant="outlined"
                                            name="team_name"
                                            value={formData.team_name}
                                            onChange={handleInputChange}
                                            required
                                            className="autocomplete-input"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setFormData({ ...formData, team_name: newValue ? newValue.name : '' });
                                    }}
                                    freeSolo
                                />
                            </td>
                            <td className="bookmaker-col">
                                <select
                                    name="bookmaker"
                                    value={formData.bookmaker}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Book</option>
                                    <option value="Winamax">Winamax</option>
                                    <option value="Parions Sport">Parions Sport</option>
                                    <option value="Unibet">Unibet</option>
                                </select>
                            </td>
                            <td className="cote-col">
                                <input
                                    type="text"
                                    name="odds"
                                    value={formData.odds}
                                    onChange={handleOddsChange}
                                    placeholder="Cote"
                                    required
                                />
                            </td>
                            <td className="profit-col">
                                <input
                                    type="text"
                                    name="profit"
                                    value={formData.profit}
                                    onChange={handleProfitChange}
                                    placeholder="Profit"
                                    required
                                />
                            </td>
                            <td className="stake-col">
                                <input
                                    type="text"
                                    name="stake"
                                    value={formData.stake}
                                    readOnly
                                    required
                                />
                            </td>
                            <td className="date-col">
                                <input
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    required
                                />
                            </td>
                            <td className="actions-col">
                                <button onClick={handleFormSubmit}>➕</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default SeriesDetails;