import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const AddTeams = () => {
    const [championships, setChampionships] = useState([]);
    const [selectedChampionship, setSelectedChampionship] = useState('');
    const [teams, setTeams] = useState('');
    const [newChampionshipName, setNewChampionshipName] = useState('');
    const [newChampionshipCountry, setNewChampionshipCountry] = useState('');

    useEffect(() => {
        const fetchChampionships = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/teams/championships`);
                console.log('Championships fetched:', response.data); // Log des championnats
                setChampionships(response.data);
            } catch (error) {
                console.error('Error fetching championships:', error);
            }
        };

        fetchChampionships();
    }, []);

    const handleAddTeams = async () => {
        const teamsArray = teams.split('\n').map(team => ({
            name: team.trim(),
            championship: selectedChampionship
        }));

        console.log('Teams to add:', teamsArray); // Log des équipes à ajouter

        try {
            await axios.post(`${API_URL}/api/teams/add`, { teams: teamsArray });
            alert('Teams added successfully');
            setTeams('');
        } catch (error) {
            console.error('Error adding teams:', error);
            alert('Failed to add teams');
        }
    };

    const handleAddChampionship = async () => {
        try {
            const response = await axios.post(`${API_URL}/api/teams/championships/add`, {
                name: newChampionshipName,
                country: newChampionshipCountry
            });
            alert('Championship added successfully');
            setNewChampionshipName('');
            setNewChampionshipCountry('');
            setChampionships([...championships, response.data]);
        } catch (error) {
            console.error('Error adding championship:', error);
            alert('Failed to add championship');
        }
    };

    return (
        <div className="add-teams-container">
            <h2>Ajouter des équipes</h2>
            <div>
                <label>Championnat:</label>
                <select
                    value={selectedChampionship}
                    onChange={(e) => setSelectedChampionship(e.target.value)}
                >
                    <option value="">Sélectionner un championnat</option>
                    {championships.map((championship, index) => (
                        <option key={index} value={championship.name}>
                            {championship.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Équipes (une par ligne):</label>
                <textarea
                    value={teams}
                    onChange={(e) => setTeams(e.target.value)}
                    rows="10"
                    cols="50"
                />
            </div>
            <button onClick={handleAddTeams}>Ajouter les équipes</button>

            <h2>Ajouter un championnat</h2>
            <div>
                <label>Nom du championnat:</label>
                <input
                    type="text"
                    value={newChampionshipName}
                    onChange={(e) => setNewChampionshipName(e.target.value)}
                />
            </div>
            <div>
                <label>Pays:</label>
                <input
                    type="text"
                    value={newChampionshipCountry}
                    onChange={(e) => setNewChampionshipCountry(e.target.value)}
                />
            </div>
            <button onClick={handleAddChampionship}>Ajouter le championnat</button>
        </div>
    );
};

export default AddTeams;