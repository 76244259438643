import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import Tabs from './Tabs';
import Tab from './Tab';
import './InvestorDetails.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

const InvestorDetails = () => {
    const { id } = useParams();
    const [investor, setInvestor] = useState(null);
    const [investments, setInvestments] = useState([]);
    const [bets, setBets] = useState([]);
    const [sortedBets, setSortedBets] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
    const [isAdmin, setIsAdmin] = useState(false);  // Admin status

    useEffect(() => {
        const init = async () => {
            await checkAdminStatus();
            await fetchInvestorDetails();
        };
        init();
    }, [id]);

    const fetchInvestorDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/api/investors/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const investorData = response.data;

            const eligibleBets = investorData.bets.filter(bet => bet.eligible);
            setInvestor(investorData);

            const sortedInvestments = investorData.investments.sort((a, b) => new Date(a.date) - new Date(b.date));
            setInvestments(sortedInvestments || []);

            setBets(eligibleBets);
            sortBets(eligibleBets);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l\'investisseur:', error);
        }
    };

    const checkAdminStatus = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("No token found in localStorage");
                return;
            }

            const response = await axios.get(`${API_URL}/auth/me`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const userData = response.data;
            setIsAdmin(userData.role === 'admin');
        } catch (error) {
            console.error('Error fetching admin status:', error);
        }
    };

    const handleAddInvestment = () => {
        const amountInput = prompt('Enter investment amount');
        const dateInput = prompt('Enter investment date (YYYY-MM-DD)');

        const amount = parseFloat(amountInput);
        const date = new Date(dateInput);

        if (isNaN(amount) || date.toString() === 'Invalid Date') {
            alert('Invalid amount or date. Please try again.');
            return;
        }

        addInvestment({ amount, date: dateInput });
    };

    const addInvestment = async (investment) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${API_URL}/api/investors/${id}/investments`, investment, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchInvestorDetails();
        } catch (error) {
            console.error('Error adding investment:', error);
        }
    };

    const handleEditInvestment = (investment) => {
        const amountInput = prompt('Enter new investment amount', investment.amount);
        const dateInput = prompt('Enter new investment date (YYYY-MM-DD)', investment.date);

        const amount = parseFloat(amountInput);
        const date = new Date(dateInput);

        if (isNaN(amount) || date.toString() === 'Invalid Date') {
            alert('Invalid amount or date. Please try again.');
            return;
        }

        updateInvestment(investment.id, { amount, date: dateInput });
    };

    const updateInvestment = async (investmentId, updatedInvestment) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/api/investors/${id}/investments/${investmentId}`, updatedInvestment, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchInvestorDetails();
        } catch (error) {
            console.error('Error updating investment:', error);
        }
    };

    const handleDeleteInvestment = async (investmentId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_URL}/api/investors/${id}/investments/${investmentId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            await fetchInvestorDetails();
        } catch (error) {
            console.error('Error deleting investment:', error);
        }
    };

    const sortBets = (bets) => {
        const sorted = [...bets].sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA - dateB;
        });

        let cumulativeProfit = 0;
        const betsWithCumulativeProfit = sorted.map(bet => {
            cumulativeProfit += parseFloat(bet.investorProfit) || 0;
            return { ...bet, cumulativeProfit: cumulativeProfit.toFixed(2) };
        });

        setSortedBets(betsWithCumulativeProfit);
    };

    const handleSortChange = () => {
        const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key: 'date', direction: newDirection });
        sortBets(bets);
    };

    const totalProfitEligible = sortedBets.reduce((acc, bet) => acc + (parseFloat(bet.investorProfit) || 0), 0);

    if (!investor) {
        return <p>Chargement des détails de l'investisseur...</p>;
    }

    const totalInvestment = investments.reduce((acc, investment) => acc + parseFloat(investment.amount), 0);

    const stats = calculateStats(sortedBets);
    const chartData = prepareChartData(sortedBets);
    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div className="investor-details-container">
            <div className="investor-summary">
                <div className="capital">💰 Capital actuel: {(totalInvestment + totalProfitEligible).toFixed(2)} €</div>
                <div className="profit">📈 Profit actuel: {totalProfitEligible.toFixed(2)} €</div>
            </div>

            <div className="investor-card">
                <Tabs>
                    <Tab label="Informations">
                        <h2>🧑 Informations de l'investisseur</h2>
                        <p>Nom : {investor.name}</p>
                        <p>Investissement total : {totalInvestment.toFixed(2)} €</p>
                    </Tab>
                    <Tab label="Stats">
                        <h2>📊 Statistiques</h2>
                        <p>Nombre de matchs joués : {stats.totalMatches}</p>
                        <p>Nombre de matchs gagnés : {stats.wins}</p>
                        <p>% de matchs gagnés : {stats.winPercentage}%</p>
                    </Tab>
                    <Tab label="Graph">
                        <h2>📈 Évolution du Profit Cumulé</h2>
                        <div className="chart-container">
                            <Line data={chartData} options={chartOptions} />
                        </div>
                    </Tab>
                </Tabs>
            </div>

            <div className="investor-card">
                <h2>💸 Investissements</h2>
                <table className="investments-table">
                    <thead>
                        <tr>
                            <th>Montant</th>
                            <th>Date</th>
                            <th>Type</th>
                            {isAdmin && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {investments.map((investment) => (
                            <tr key={investment.id}>
                                <td>{parseFloat(investment.amount).toFixed(2)} €</td>
                                <td>{new Date(investment.date).toLocaleDateString()}</td>
                                <td>{investment.amount < 0 ? 'Retrait' : 'Dépôt'}</td>
                                {isAdmin && (
                                    <td>
                                        <button onClick={() => handleEditInvestment(investment)}>Modifier</button>
                                        <button onClick={() => handleDeleteInvestment(investment.id)}>Supprimer</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isAdmin && <button onClick={handleAddInvestment}>Ajouter un investissement</button>}
            </div>

            <div className="investor-card">
                <h2>📋 Historique des paris</h2>
                <table className="bets-list">
                    <thead>
                        <tr>
                            <th>Nom de l'équipe</th>
                            <th>Cote</th>
                            <th>Mise</th>
                            <th>Profit</th>
                            <th>Date <button onClick={handleSortChange}>{sortConfig.direction === 'asc' ? '⬆️' : '⬇️'}</button></th>
                            <th>Profit cumulé</th>
                            <th>Résultat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedBets.map((bet) => (
                            <tr key={bet.bet_id}>
                                <td>{bet.team_name}</td>
                                <td>{parseFloat(bet.odds).toFixed(2)}</td>
                                <td>{parseFloat(bet.stake).toFixed(2)} €</td>
                                <td>{!isNaN(parseFloat(bet.investorProfit)) ? parseFloat(bet.investorProfit).toFixed(2) + ' €' : '0.00 €'}</td>
                                <td>{new Date(bet.date).toLocaleDateString()}</td>
                                <td>{!isNaN(parseFloat(bet.cumulativeProfit)) ? bet.cumulativeProfit + ' €' : '0.00 €'}</td>
                                <td>{bet.result === 'won' ? '✔️ Gagné' : '❌ Perdu'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const calculateStats = (bets) => {
    const totalMatches = bets.length;
    const wins = bets.filter(bet => bet.result === 'won').length;
    const winPercentage = totalMatches > 0 ? ((wins / totalMatches) * 100).toFixed(2) : '0.00';
    return { totalMatches, wins, winPercentage };
};

const prepareChartData = (bets) => {
    const labels = bets.map(bet => new Date(bet.date).toLocaleDateString());
    const data = bets.map(bet => parseFloat(bet.cumulativeProfit));

    return {
        labels,
        datasets: [
            {
                label: 'Profit Cumulé',
                data,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
        ],
    };
};

export default InvestorDetails;
