import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './HistoricalSeries.css';

const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

// Mapping sport names to emojis
const sportEmojis = {
    foot: '⚽',
    basket: '🏀',
    tennis: '🎾',
    // Add more sports and their corresponding emojis if needed
};

function HistoricalSeries() {
    const [closedSeries, setClosedSeries] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedSport, setSelectedSport] = useState('');
    const [selectedBetType, setSelectedBetType] = useState('all'); // Default to 'all'

    // Function to fetch closed series from the API
    const fetchClosedSeries = async () => {
        try {
            const response = await axios.get(`${API_URL}/api/series/closed`);
            if (!Array.isArray(response.data)) {
                throw new Error('Response data is not an array');
            }

            const sortedSeries = response.data.map(series => {
                let cumulativeProfit = 0;
                const bets = series.bets.map(bet => {
                    const profitOrLoss = bet.result === 'won'
                        ? (parseFloat(bet.stake) * parseFloat(bet.odds) - parseFloat(bet.stake))
                        : -parseFloat(bet.stake);
                    cumulativeProfit += profitOrLoss;
                    return { ...bet, cumulativeProfit: bet.result === 'won' ? cumulativeProfit : undefined };
                });
                return {
                    ...series,
                    bets: bets.sort((a, b) => a.bet_id - b.bet_id),
                    cumulativeProfit
                };
            });

            let totalCumulativeProfit = 0;
            const seriesWithTotalProfit = sortedSeries.map(series => {
                totalCumulativeProfit += series.cumulativeProfit;
                return { ...series, totalCumulativeProfit };
            });

            setClosedSeries(seriesWithTotalProfit);
        } catch (error) {
            console.error('Error fetching closed series:', error);
            alert('Error fetching closed series: ' + error.message);
        }
    };

    // Function to handle eligibility changes
    const handleEligibleChange = async (seriesId, eligible) => {
        try {
            await axios.put(`${API_URL}/api/series/${seriesId}/eligibility`, { eligible });
            fetchClosedSeries();  // Re-fetch series after updating eligibility
        } catch (error) {
            console.error('Failed to update series eligibility:', error);
            alert('Failed to update series eligibility.');
        }
    };

    // Sorting logic
    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Sorting and filtering the series
    const sortedSeries = closedSeries
        .filter(series => {
            // Filter by sport
            if (selectedSport && series.sport !== selectedSport) return false;

            // Filter by bet type
            if (selectedBetType !== 'all' && series.type !== selectedBetType) return false;

            // Filter by date
            const seriesDate = new Date(series.bets[0]?.date || 0);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            if (start && seriesDate < start) return false;
            if (end && seriesDate > end) return false;
            return true;
        })
        .sort((a, b) => {
            if (sortConfig.key === 'date') {
                const dateA = new Date(a.bets[0]?.date || 0);
                const dateB = new Date(b.bets[0]?.date || 0);
                return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
            } else if (sortConfig.key === 'cumulativeProfit') {
                return sortConfig.direction === 'asc'
                    ? a.cumulativeProfit - b.cumulativeProfit
                    : b.cumulativeProfit - a.cumulativeProfit;
            }
            return 0;
        });

    useEffect(() => {
        fetchClosedSeries();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? 'Invalid Date' : date.toLocaleDateString();
    };

    return (
        <div className="historical-series-container">
            <div className="card">
                <h1>📊 Historique des Séries</h1>

                {/* Filter by date, sport, and series type */}
                <div className="filters">
                    <div className="date-filter">
                        <label>
                            Date de début :
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </label>
                        <label>
                            Date de fin :
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </label>
                    </div>

                    <div className="sport-filter">
                        <label>
                            Sport :
                            <select value={selectedSport} onChange={(e) => setSelectedSport(e.target.value)}>
                                <option value="">Tous les sports</option>
                                <option value="foot">⚽ Foot</option>
                                <option value="basket">🏀 Basket</option>
                                <option value="tennis">🎾 Tennis</option>
                            </select>
                        </label>
                    </div>

                    {/* Filter by bet type */}
                    <div className="bet-type-filter">
                        <label>
                            Type de série :
                            <select value={selectedBetType} onChange={(e) => setSelectedBetType(e.target.value)}>
                                <option value="all">Series Type: All</option>
                                <option value="équipe">Series Type: Équipe</option>
                                <option value="nombre de tirs - match">Series Type: Nombre de tirs - Match</option>
                                <option value="nombre de tirs - équipe">Series Type: Nombre de tirs - Équipe</option>
                                <option value="nombre de tirs cadrés - match">Series Type: Nombre de tirs cadrés - Match</option>
                                <option value="nombre de tirs cadrés - équipe">Series Type: Nombre de tirs cadrés - Équipe</option>
                                <option value="nombre de sets">Series Type: Nombre de sets</option>
                                <option value="nombre de jeux">Series Type: Nombre de jeux</option>
                                <option value="nombre d'aces">Series Type: Nombre d'aces</option>
                            </select>
                        </label>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Sport</th>
                            <th>ID Série</th>
                            <th>Nom Série</th>
                            <th>Type de série</th> {/* New column for series type */}
                            <th>Éligible</th>
                            <th>ID Pari</th>
                            <th>Équipe</th>
                            <th>
                                Date
                                <button onClick={() => handleSort('date')}>
                                    {sortConfig.key === 'date' && sortConfig.direction === 'asc' ? '⬆️' : '⬇️'}
                                </button>
                            </th>
                            <th>Cote</th>
                            <th>Profit (€)</th>
                            <th>Mise (€)</th>
                            <th>Résultat</th>
                            <th>
                                Cumul Profit/Perte (€)
                                <button onClick={() => handleSort('cumulativeProfit')}>
                                    {sortConfig.key === 'cumulativeProfit' && sortConfig.direction === 'asc' ? '⬆️' : '⬇️'}
                                </button>
                            </th>
                            <th>Total Cumulative Profit (€)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedSeries.length > 0 ? (
                            sortedSeries.map(series => series.bets.map((bet, betIndex) => (
                                <tr key={bet.bet_id}>
                                    <td>{sportEmojis[series.sport] || ''} {series.sport}</td>
                                    <td>{series.series_id}</td>
                                    <td>
                                        <Link to={`/series/${series.series_id}`}>
                                            {series.name || 'undefined'}
                                        </Link>
                                    </td>
                                    <td>{series.type}</td> {/* Displaying the series type here */}
                                    <td>
                                        <input 
                                            type="checkbox" 
                                            checked={series.eligible}
                                            onChange={(e) => handleEligibleChange(series.series_id, e.target.checked)} 
                                        />
                                    </td>
                                    <td>{bet.bet_id}</td>
                                    <td>{bet.team_name}</td>
                                    <td>{formatDate(bet.date)}</td>
                                    <td>{parseFloat(bet.odds).toFixed(2)}</td>
                                    <td>{parseFloat(bet.profit).toFixed(2)} €</td>
                                    <td>{parseFloat(bet.stake).toFixed(2)} €</td>
                                    <td>{bet.result}</td>
                                    <td>{bet.cumulativeProfit !== undefined ? bet.cumulativeProfit.toFixed(2) + ' €' : ''}</td>
                                    {betIndex === series.bets.length - 1 && (
                                        <td>{series.totalCumulativeProfit.toFixed(2)} €</td>
                                    )}
                                </tr>
                            )))
                        ) : (
                            <tr>
                                <td colSpan="14">Aucune série fermée trouvée</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HistoricalSeries;
