import React from 'react';

function Home() {
    return (
        <div>
            <h1>Welcome to the Betting Series Manager</h1>
            <p>This is your go-to app for managing your betting series and analyzing your betting strategies.</p>
        </div>
    );
}

export default Home;
