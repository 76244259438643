import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Importez useNavigate pour la redirection

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Initialisez useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://my-investor-app-9622243531b6.herokuapp.com/auth/login', 
        { email, password }, 
        { withCredentials: true }
      );        
      
      console.log('Response:', response);
      
      if (response.data.message === 'Logged in successfully') {
        const { token, role, investorId } = response.data;

        localStorage.setItem('token', token);  
        localStorage.setItem('isAuthenticated', true);
        console.log('Storing user role:', role);
        localStorage.setItem('role', role);

        // Redirigez vers la page de profil de l'investisseur
        navigate(`/investors/${investorId}`);
      } else {
        setError('Invalid credentials');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
