import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Logs.css';

const Logs = () => {
    const [bets, setBets] = useState([]);
    const [expandedTeams, setExpandedTeams] = useState({});

    useEffect(() => {
        fetch('https://my-investor-app-9622243531b6.herokuapp.com/api/bets/logs')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Bets data received:', data);
                setBets(data);
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const toggleTeam = (teamName) => {
        setExpandedTeams(prevState => ({
            ...prevState,
            [teamName]: !prevState[teamName]
        }));
    };

    const groupedBets = bets.reduce((acc, bet) => {
        if (!acc[bet.team_name]) {
            acc[bet.team_name] = [];
        }
        acc[bet.team_name].push(bet);
        return acc;
    }, {});

    return (
        <div className="logs-container">
            <h2>Logs des Paris</h2>
            <table className="logs-table">
                <thead>
                    <tr>
                        <th>Équipe</th>
                        <th>Résultat</th>
                        <th>Mise</th>
                        <th>Cote</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(groupedBets).map((teamName, index) => (
                        <React.Fragment key={index}>
                            <tr className="team-row" onClick={() => toggleTeam(teamName)}>
                                <td colSpan="5">
                                    {teamName} ({groupedBets[teamName].length})
                                    <button className="toggle-button">
                                        {expandedTeams[teamName] ? '-' : '+'}
                                    </button>
                                </td>
                            </tr>
                            {expandedTeams[teamName] && groupedBets[teamName].map((bet, betIndex) => (
                                <tr key={betIndex}>
                                    <td>{bet.team_name}</td>
                                    <td>{bet.result}</td>
                                    <td>{bet.stake}</td>
                                    <td>{bet.odds}</td>
                                    <td>
                                        <Link to={`/series/${bet.series_id}`}>
                                            <button>Voir Série</button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Logs;