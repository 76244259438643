import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    const userRole = localStorage.getItem('role');  // Récupération du rôle de l'utilisateur (admin ou investor)

    const handleLogout = () => {
        localStorage.removeItem('isAuthenticated'); 
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        navigate('/login');
    };

    // Si l'utilisateur n'est pas authentifié, ne pas afficher le menu
    if (!isAuthenticated) {
        return null;
    }

    return (
        <nav className="app-nav">
            <ul>
                {/* Si l'utilisateur est un admin, afficher le menu complet */}
                {userRole === 'admin' && (
                    <>
                        <li><Link to="/series">Séries</Link></li>
                        <li><Link to="/historical">Historique</Link></li>
                        <li><Link to="/goals">Suivi des Objectifs</Link></li>
                        <li><Link to="/investors">Investisseurs</Link></li>
                        <li><Link to="/stats">Statistiques</Link></li> {/* Harmoniser le design */}
                        <li><Link to="/logs">Logs</Link></li> {/* Ajouter le bouton Logs */}
                        <li><Link to="/add-teams">Ajouter des équipes</Link></li> {/* Ajouter le bouton Ajouter des équipes */}
                    </>
                )}
                {/* Afficher le bouton de déconnexion pour tous les utilisateurs */}
                <li><button onClick={handleLogout}>Déconnexion</button></li>
            </ul>
        </nav>
    );
};

export default Navbar;
