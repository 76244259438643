import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Investors.css';

// Utiliser l'URL de l'API à partir des variables d'environnement
const API_URL = 'https://my-investor-app-9622243531b6.herokuapp.com';

function Investors() {
    const [investors, setInvestors] = useState([]);
    const [newInvestor, setNewInvestor] = useState({ name: '', investment: '', capital: '', date: new Date().toISOString().split('T')[0] });

    useEffect(() => {
        fetchInvestors();
    }, []);

    const fetchInvestors = async () => {
        try {
            const token = localStorage.getItem('token'); // Récupère le token JWT depuis le localStorage
            console.log('Sending token:', token); // Ajout du log pour vérifier le token
            const response = await axios.get(`${API_URL}/api/investors`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Ajoute le token JWT dans les en-têtes de la requête
                },
            });
            setInvestors(response.data);
        } catch (error) {
            console.error('Error fetching investors:', error);
        }
    };
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewInvestor({ ...newInvestor, [name]: value });
    };

    const handleAddInvestor = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${API_URL}/api/investors`, newInvestor, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchInvestors();
        } catch (error) {
            console.error('Error adding investor:', error);
        }
    };

    const handleDeleteInvestor = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_URL}/api/investors/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchInvestors();
        } catch (error) {
            console.error('Error deleting investor:', error);
        }
    };

    return (
        <div className="investors-container">
            <h1>Investors</h1>
            <form onSubmit={handleAddInvestor} className="add-investor-form">
                <input
                    type="text"
                    name="name"
                    value={newInvestor.name}
                    onChange={handleInputChange}
                    placeholder="Investor Name"
                    required
                />
                <input
                    type="number"
                    name="investment"
                    value={newInvestor.investment}
                    onChange={handleInputChange}
                    placeholder="Investment Amount"
                    required
                />
                <input
                    type="number"
                    name="capital"
                    value={newInvestor.capital}
                    onChange={handleInputChange}
                    placeholder="Capital Amount"
                    required
                />
                <input
                    type="date"
                    name="date"
                    value={newInvestor.date}
                    onChange={handleInputChange}
                    required
                />
                <button type="submit">Add Investor</button>
            </form>
            <ul className="investors-list">
                {investors.map(investor => (
                    <li key={investor.id} className="investor-item">
                        <Link to={`/investors/${investor.id}`} className="investor-link">
                            {investor.name} - {investor.investment}€
                        </Link>
                        <button onClick={() => handleDeleteInvestor(investor.id)} className="delete-button">Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Investors;
